/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Slider from "react-slick"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  HeroGradient,
  PrevArrow,
  NextArrow,
  VideoPlayer,
  CentersOfExcellenceSideNav,
  PatientStoryGridCenters,
  CallToActionSchedule,
} from "../components/elements"

const CenterOfExcellence = ({ data, pageContext }) => {
  const page = data.datoCmsCenterOfExcellence

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const slug = pageContext.slug

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Hero>
        <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
          <HeroGradient>
            <Container sx={{ px: "1em", py: [5, 5, 6] }}>
              <Styled.h1>{page.title}</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel={page.title}
              />
            </Container>
          </HeroGradient>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </BackgroundImage>
      </Hero>

      <Container sx={{ px: "1em", py: 5 }}>
        <Box
          width="full"
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{ paddingRight: [0, 0, 3], width: ["full", "full", "70%"] }}
            >
              <Styled.h6 as="p">{page.introContent}</Styled.h6>
            </Box>
            <Box sx={{ width: ["full", "full", "30%"] }}>
              <CallToActionSchedule />
            </Box>
          </Box>

          {/* Sidebar */}
          <Box
            as="aside"
            id="sidebar"
            sx={{
              bg: ["transparent", "transparent", "warmgray"],
              flexBasis: "sidebar",
            }}
            data-datocms-noindex
            width={["full"]}
          >
            <CentersOfExcellenceSideNav page={page} />
          </Box>

          <Box
            as="main"
            sx={{
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 320,
            }}
          >
            {/* Content */}
            <Box as="section" id="content">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={{ width: ["full"], pl: [0, 0, 4] }}>
                  {page.video && (
                    <Box sx={{ my: 4 }}>
                      <VideoPlayer url={page.video.url} />
                    </Box>
                  )}

                  {page?.contentNode && (
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: page?.contentNode.childMarkdownRemark.html,
                      }}
                    />
                  )}

                  {page.images.length > 0 ? (
                    <Section id="gallery">
                      <Container sx={{ px: "1em" }}>
                        <Flex
                          sx={{
                            flexWrap: "wrap",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <Box sx={{ width: ["full"] }}>
                            <Slider {...settings} sx={{ color: "white" }}>
                              {page.images &&
                                page.images.map(img => (
                                  <img
                                    src={img.url}
                                    alt="Facilities"
                                    key={img.url}
                                  />
                                ))}
                            </Slider>
                          </Box>
                        </Flex>
                      </Container>
                    </Section>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <PatientStoryGridCenters slug={slug} />
    </Layout>
  )
}

export default CenterOfExcellence

export const query = graphql`
  query centerOfExcellencePageQuery($slug: String!) {
    datoCmsCenterOfExcellence(slug: { eq: $slug }) {
      conditionsWeTreat
      conditionsWeTreatNode {
        childMarkdownRemark {
          html
        }
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      meetYourTeamFindADocSpecialtyId
      resources
      resourcesNode {
        childMarkdownRemark {
          html
        }
      }
      relatedServicesIntroNode {
        childMarkdownRemark {
          html
        }
      }
      relatedProgramsIntroNode {
        childMarkdownRemark {
          html
        }
      }
      introContent
      id
      position
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      meta {
        status
      }
      images {
        url
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      relatedPrograms {
        title
        slug
        id
      }
      relatedBlogs {
        author {
          slug
          name
        }
        title
        slug
        date(formatString: "MM/d/yyyy")
        id
        meta {
          status
        }
        blogImage {
          fluid(maxWidth: 240, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      relatedServices {
        id
        slug
        title
      }
      video {
        url
      }
      logos {
        url
        logo {
          alt
          fluid(maxWidth: 250, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        id
      }
      seoMetaTags {
        tags
      }
    }
  }
`
